import Vue from "vue";
import App from "./App.vue";
import { VBToggle,BCard,BButton,BCollapse } from 'bootstrap-vue'

Vue.component('b-button', BButton)
Vue.directive('b-toggle', VBToggle)
Vue.component('b-card', BCard)
Vue.component('b-collapse', BCollapse)

Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
}).$mount("#app");
