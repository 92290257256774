<template>
  <div class="pt-10 headline">
    <div role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            text-decoration-none
            block
            href="#"
            v-b-toggle.accordion-1
            variant="info"
            >Fruits are rare</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text class="headtext"
              >RSS Fruits Token (RTF) are demon fruits for those who contribute
              significantly to the open protocol of RSS3</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.accordion-2 variant="info"
            >Unique of 147 pair</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text class="headtext"
              >There will be in total of 294 RFTs. Every one is
              unique.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.accordion-3 variant="info"
            >Power lies within</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text class="headtext"
              >Hodlers of RFT will have the power to govern and benefit from the
              future of RSS3</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.accordion-4 variant="info"
            >Get one if you dare</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text class="headtext"
              >Those who dare to get one will be chosen by the Demon of
              Fruits</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div class="footer">
    <a href="https://rss3.io/">
      <img
        class="footer-logo"
        alt="RSS3 logo"
        src="./../assets/rss3-logo.png"
      />
    </a>
  </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pt-10 {
  padding-top: 10%;
}
.headline {
  color: #ffffff;
  font-size: 1.8rem;
}
a {
  color: #ffffff;
  text-decoration: none;
}
a:visited,
a:hover,
a:active,
a:focus {
  outline: none;
}
.headtext {
  color: #9ee52b;
  font-size: 1.3rem;
}
.not-collapsed {
  color: #9ee52b;
}
.footer {
  /* position: absolute; */
  bottom: 0;
  padding: 0;
  padding-top: 12.5vh;
  margin: 0;
  width: 100%;
}
.footer-logo {
  width: 15vh;
}
</style>
