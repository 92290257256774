<template>
  <div id="app">
    <img alt="RTF logo" style="width: 30vh" src="./assets/RTF.gif" />
    <Home />
  </div>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
html {
  touch-action: none;
  touch-action: pan-y;
  overflow: auto;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  background-color: #424242;
  -ms-overflow-style: none;
}
#app {
  font-family: "ChicagoFLF";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  min-height: calc(100vh - 50px);
}
@font-face {
  font-family: "ChicagoFLF";
  src: url("assets/fonts/ChicagoFLF.ttf") format("truetype");
}
</style>
